// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$name-events-frontend-primary: mat.define-palette(mat.$indigo-palette);
$name-events-frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$name-events-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$name-events-frontend-theme: mat.define-light-theme((
  color: (
    primary: $name-events-frontend-primary,
    accent: $name-events-frontend-accent,
    warn: $name-events-frontend-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($name-events-frontend-theme);

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

@import 'assets/scss/header';

:root {
  --primary: #334255;
  --highlight: #e46d00;
  --background: #f7f7f7;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

h2 {
  color: var(--primary);
}

h4 {
  color: var(--primary);
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-80 {
  margin-bottom: 80px;
}

.py-5 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important
}

.bg-white {
  background-color: white;
}

.bg-primary {
  background-color: #333333 !important;
}

.content {
  padding-top: 75px;
  min-height: 80vh;
}

.section-title {
  position: relative;
}

.section-title .big-title {
  margin: 0;
  padding: 0;
  font-size: 40px;
  font-weight: 400;
  color: var(--primary);
}

.section-title .sub-title {
  margin-bottom: 15px;
  letter-spacing: 5px;
  color: var(--highlight);
  font-size: 24px;
  text-transform: uppercase;
}

.section-title .line-style {
  z-index: 1;
  top: -30px;
  left: -30px;
  width: 100px;
  height: 3px;
  position: absolute;
  background-color: var(--highlight);
}

.section-paragraph {
  line-height: 25px;
}

.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);

  &:hover {
    background-color: var(--primary);
    border-color: var(--primary);
  }

  &:active {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
  }

}

.btn-outline-primary {
  color: black;
  border-color: var(--primary);

  &:hover {
    background-color: var(--primary);
    border-color: var(--primary);
  }

  &:active {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
  }

}

.featured-text {
  text-decoration: none;
  color: var(--highlight);
}

.form-text {
  color: darkred;
}

.icon-circle {
  width: 35px;
  height: 35px;
  color: white;
  background-color: var(--primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.icon-text {
  display: flex;
  align-items: center;
}

.fc {
  color: black;
  text-decoration: none;
}

.fc .fc-col-header-cell-cushion {
  color: black;
  text-decoration: none;
}

.fc .fc-daygrid-day-number {
  color: black;
  text-decoration: none;
}

.fc-direction-ltr .fc-list-day-side-text, .fc-direction-rtl .fc-list-day-text {
  color: black;
  text-decoration: none;
}

.fc .fc-list-day-text {
  color: black;
  text-decoration: none;
}

.event {
  color: white;
  cursor: pointer;
  border: 0;
  &:hover {
    background-color: var(--highlight);
  }
}

.event-business {
  background-color: darkblue;
}

.event-entertainment {
  background-color: purple;
}

.event-festival {
  background-color: orange;
}

.event-food {
  background-color: green;
}

.event-lifestyle {
  background-color: teal;
}

.event-music {
  background-color: black;
}

.event-nightlife {
  background-color: darkred;
}

.event-sports {
  background-color: darkgreen;
}
